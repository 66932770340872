import React, { useEffect } from "react";
import SecondaryBanner from "../../Components/SecondaryBanner";
import { Accordion } from "react-bootstrap";
import { Table } from "../../Components/Table";

const BaseRate = () => {
  useEffect(() => {
    document.title = "Base Rate & Net Spread Interest Rate";
    window.scrollTo(0, 0);
  }, []);
  const bannerData = {
    breadcrumb: [
      "Home",
      "Investor Relationship",
      "Base Rate & Net Spread Interest Rate",
    ],
    bannerTitle: "Base Rate & Net Spread Interest Rate",
  };
  const tableHeader = ["Month", "Base Rate", "Spread Rate"];
  const tableData2081 = [
    ["Ashwin 2081", "9.72%", "4.54%"],
    ["Previous Three Months Average Bases Rate", "9.79%", "-"],
    ["Bhadra 2081", "9.74%", "4.58%"],
    ["Previous Three Months Average Bases Rate", "9.98%", "-"],
    ["Shrawan 2081", "9.90%", "4.58%"],
    ["Previous Three Months Average Bases Rate", "10.21%", "-"],
  ];
  const tableData2080 = [
    ["Asadh 2081", "10.29%", "4.55%"],
    ["Previous Three Months Average Bases Rate", "10.42%", "-"],
    ["Jestha 2081", "10.43%", "4.57%"],
    ["Previous Three Months Average Bases Rate", "10.60%", "-"],
    ["Baisakh 2081", "10.55%", "4.55%"],
    ["Previous Three Months Average Bases Rate", "10.87%", "-"],
    ["Chaitra 2080", "10.81%", "4.54%"],
    ["Previous Three Months Average Bases Rate", "11.16%", "-"],
    ["Falgun 2080", "11.26%", "4.56%"],
    ["Previous Three Months Average Bases Rate", "11.41%", "-"],
    ["Magh 2080", "11.40%", "4.57%"],
    ["Previous Three Months Average Base Rate", "11.58%", "-"],
    ["Poush 2080", "11.58%", "4.56%"],
    ["Previous Three Months Average Base Rate", "11.76%", "-"],
    ["Mangsir 2080", "11.76%", "4.54%"],
    ["Previous Three Months Average Base Rate", "11.95%", "-"],
    ["Kartik 2080", "11.95%", "4.57%"],
    ["Previous Three Months Average Base Rate", "12.06%", "-"],
    ["Ashwin 2080", "12.14%", "4.49%"],
    ["Previous Three Months Average Base Rate", "11.96%", "-"],
    ["Bhadra 2080", "12.09%", "4.56%"],
    ["Previous Three Months Average Base Rate", "11.96%", "-"],
    ["Shrawan 2080", "11.66%", "4.54%"],
    ["Previous Three Months Average Base Rate", "11.94%", "-"],
  ];
  const tableData2079 = [
    ["Asadh 2080", "12.13%", "4.57%"],
    ["Previous Three Months Average Base Rate", "12.16%", "-"],
    ["Jestha 2080", "12.03%", "4.73%"],
    ["Previous Three Months Average Base Rate", "12.32%", "-"],
    ["Baishakh 2080", "12.32%", "4.74%"],
    ["Previous Three Months Average Base Rate", "12.54%", "-"],
    ["Chaitra 2079", "12.60%", "4.73%"],
    ["Third Quarter Average Base Rate", "12.71%", "-"],
    ["Falgun 2079", "12.71%", "4.74%"],
    ["Magh 2079", "12.82%", "4.78%"],
    ["Poush 2079", "12.86%", "4.77%"],
    ["Mangsir 2079", "12.71%", "4.89%"],
    ["Kartik 2079", "12.72%", "4.89%"],
    ["Ashoj  2079", "12.67%", "4.79%"],
    ["Bhadra  2079", "12.84%", "4.81%"],
    ["Shrawan 2079", "12.46%", "4.93%"],
  ];

  const tableData2078 = [
    ["Ashad 2079", "11.93%", "4.86%"],
    ["Jestha 2079", "11.20%", "4.90%"],
    ["Baisakh 2079", "11.48%", "4.90%"],
    ["Chaitra 2078", "11.92%", "4.67%"],
    ["Falgun 2078", "11.33%", "4.57%"],
    ["Magh 2078", "11.77%", "4.74%"],
    ["Poush 2078", "10.77%", "3.83%"],
    ["Mangsir 2078", "10.22%", "4.00%"],
    ["Kartik 2078", "9.74%", "4.60%"],
    ["Ashoj 2078", "9.74%", "4.60%"],
    ["Bhadra 2078", "10.41%", "4.70%"],
    ["Shrawan 2078", "10.26%", "4.73%"],
  ];
  const tableData2077 = [
    ["Ashad 2078", "9.40%", "4.50%"],
    ["Jestha 2078", "10.05%", "4.40%"],
    ["Baisakh 2078", "9.89%", "4.52%"],
    ["Chaitra 2077", "9.52%", "4.75%"],
    ["Falgun 2077", "9.64%", "4.75%"],
    ["Magh 2077", "9.74%", "4.87%"],
    ["Poush 2077", "9.77%", "4.87%"],
    ["Mangsir 2077", "9.73%", "4.69%"],
    ["Kartik 2077", "10.00%", "4.70%"],
    ["Ashoj 2077", "10.03%", "4.78%"],
    ["Bhadra 2077", "0.16%", "4.62%"],
    ["Shrawan 2077", "10.16%", "4.61%"],
  ];
  const tableData2076 = [
    ["Ashad 2077", "10.69%", "6.02%"],
    ["Jestha 2077", "12.27%", "6.02%"],
    ["Baisakh 2077", "12.27%", "6.02%"],
    ["Chaitra 2076", "12.82%", "5.61%"],
  ];
  return (
    <main className="base-net-interest">
      <SecondaryBanner bannerData={bannerData} />
      <section className="section-table">
        <div className="container">
          <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
              <Accordion.Header>Fiscal Year 2081/82</Accordion.Header>
              <Accordion.Body>
                <Table tableHeader={tableHeader} tableData={tableData2081} />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Fiscal Year 2080/81</Accordion.Header>
              <Accordion.Body>
                <Table tableHeader={tableHeader} tableData={tableData2080} />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Fiscal Year 2079/80</Accordion.Header>
              <Accordion.Body>
                <Table tableHeader={tableHeader} tableData={tableData2079} />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>Fiscal Year 2078/79</Accordion.Header>
              <Accordion.Body>
                <Table tableHeader={tableHeader} tableData={tableData2078} />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>Fiscal Year 2077/78</Accordion.Header>
              <Accordion.Body>
                <Table tableHeader={tableHeader} tableData={tableData2077} />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>Fiscal Year 2076/77</Accordion.Header>
              <Accordion.Body>
                <Table tableHeader={tableHeader} tableData={tableData2076} />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </section>
    </main>
  );
};

export default BaseRate;
